import React from "react";
import * as Styles from "./project.module.css";
import { GatsbyImage } from "gatsby-plugin-image";

const Project = (props) => {
  return (
    <div className={props.hasLink ? Styles.hasLink : ""}>
      <GatsbyImage
        image={props.image}
        alt={props.alt}
        width="628"
        height="463"
      />
      <h2 className={Styles.heading}>{props.heading}</h2>
      <div className={Styles.info}>{props.info}</div>
    </div>
  );
};

export default Project;
