// extracted by mini-css-extract-plugin
export var arrow = "index-module--arrow--7b81f";
export var footerHeading = "index-module--footer-heading--f71ab";
export var heading = "index-module--heading--e875f";
export var hero = "index-module--hero--45756";
export var image = "index-module--image--0fe32";
export var intro = "index-module--intro--37b2c";
export var main = "index-module--main--1ba09";
export var mainHeading = "index-module--main-heading--32ac0";
export var project = "index-module--project--19409";
export var projects = "index-module--projects--a04cd";
export var subheading = "index-module--subheading--e602d";
export var wide = "index-module--wide--34e7d";