import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Header from "../components/header";
import Container from "../components/container";
import * as Styles from "./index.module.css";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Project from "../components/project";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query projects {
      figurenine: file(relativePath: { eq: "projects/figurenine.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      findout: file(relativePath: { eq: "projects/findout.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      easypractice: file(relativePath: { eq: "projects/easypractice.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      thebigyear: file(relativePath: { eq: "projects/thebigyear.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      bryonybennett: file(relativePath: { eq: "projects/bryonybennett.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      morocco: file(relativePath: { eq: "projects/morocco.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      bywb: file(relativePath: { eq: "projects/bywb.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      raisingit: file(relativePath: { eq: "projects/raisingit.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const hero = React.createRef();
  const main = React.createRef();

  const [wordIndex, setWordIndex] = useState(0);

  const welcome = ["hello", "你好", "g'day", "hej", "hello"];

  useEffect(() => {
    if (wordIndex < welcome.length - 1) {
      setTimeout(() => setWordIndex(wordIndex + 1), 800);
    }
    const setHeight = () => {
      const windowHeight = window.innerHeight + "px";
      hero.current.style.height = windowHeight;
      main.current.style.top = windowHeight;
    };

    setHeight();
  });

  return (
    <Layout>
      <Helmet>
        <title>Tom Laker - Designer, Photographer, Developer.</title>
        <meta
          name="description"
          content="Graphic Designer with over a decade experience, originating from the UK but residing in Stockholm, Sweden."
        />
      </Helmet>
      <section className={Styles.hero} ref={hero}>
        <Header light={true} />
        {/* <StaticImage
          src="../images/home.jpg"
          className={Styles.image}
          alt="The light burning through mist in the forest during the early morning in Victoria, Australia."
        /> */}
        <div className={Styles.intro}>
          <Container>
            <div className={Styles.subheading}>Storytelling through</div>
            <div className={Styles.heading}>
              Design
              <br />
              Photography
              <br />
              Code
              <br />
            </div>
            <svg
              className={Styles.arrow}
              width="136"
              height="156"
              viewBox="0 0 136 156"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73 139.929V0H63V139.929L8.00002 84.9289L0.928955 92L64.4645 155.536H71.5356L135.071 92L128 84.9289L73 139.929Z"
                fill="white"
              />
            </svg>
          </Container>
        </div>
      </section>
      <main className={Styles.main} ref={main}>
        <Container>
          <div className={Styles.mainHeading}>Recent work</div>
          <div className={Styles.projects}>
            <div className={`${Styles.project} ${Styles.wide}`}>
              <Project
                image={data.figurenine.childImageSharp.gatsbyImageData}
                alt="Figure Nine logo and website."
                heading="Figure Nine — Rope access company producing the world's biggest natural history productions."
                info="Identity design, web design and development"
                wide={true}
              />
            </div>
            <Link to="/findout" className={Styles.project}>
              <Project
                image={data.findout.childImageSharp.gatsbyImageData}
                alt="iPhones showing the FindOut web app."
                heading="FindOut — App to find LGBTQ+ services across London."
                info="Web app design and development"
                hasLink={true}
              />
            </Link>
            <a
              href="https://easypractice.net/"
              target="_blank"
              rel="noreferrer"
              className={Styles.project}
            >
              <Project
                image={data.easypractice.childImageSharp.gatsbyImageData}
                alt="A number of iPads showing the EasyPractice website"
                heading="EasyPractice — Healthcare management and booking software."
                info="Web design and development"
                hasLink={true}
              />
            </a>

            <Link
              to="/thebigyear"
              className={`${Styles.project} ${Styles.wide}`}
            >
              <Project
                image={data.thebigyear.childImageSharp.gatsbyImageData}
                alt="Pelicans and a list of bird names portraying the big year project."
                heading="The Big Year — 191 species of birds photographed in a calendar year."
                info="Photography, web design and development"
                hasLink={true}
              />
            </Link>
            <a
              href="https://bryonybennett.com/"
              target="_blank"
              className={Styles.project}
            >
              <Project
                image={data.bryonybennett.childImageSharp.gatsbyImageData}
                alt="iPads showing the Bryony Bennett website."
                heading="Bryony Bennett — Sustainable fashion consultancy."
                info="Web design and development"
                hasLink={true}
              />
            </a>

            <div className={Styles.project}>
              <Project
                image={data.morocco.childImageSharp.gatsbyImageData}
                alt="Three men sat under trees making breakfast at the top of the Atlas Mountains in Morocco."
                heading="Morocco — Published photo story on adventure and climate."
                info="Photography"
              />
            </div>

            <div className={Styles.project}>
              <Project
                image={data.bywb.childImageSharp.gatsbyImageData}
                alt="The before you were born book open on a double page spread of Yellow Water."
                heading="Before you were born — Photography book."
                info="Photography and book design"
              />
            </div>

            <div className={Styles.project}>
              <Project
                image={data.raisingit.childImageSharp.gatsbyImageData}
                alt="9 charity websites layed out on a beige background."
                heading="RaisingIT — Hundreds of websites for charities."
                info="Web design and development"
                className={Styles.project}
              />
            </div>
          </div>
          <div className={Styles.footerHeading}>say hello</div>
        </Container>
      </main>
      <Footer fixedHeader={true} />
    </Layout>
  );
};

export default IndexPage;
